<template>
  <div class="flex flex-col h-full">
    <div class="mb-8 flex justify-between">
      <h1 class="text-4xl font-bold text-gray-800">Settings</h1>
    </div>
    <section class="flex-auto">
      <div class="bg-white shadow-lg rounded-sm mb-8 h-full">
        <div class="flex flex-col md:flex-row md:-mr-px h-full">
          <SettingSidebar />
          <div class="grow flex flex-col">
            <router-view />
          </div>
        </div>
      </div>
    </section>

    <ModalBasic
      id="basic-modal"
      :modalOpen="successfully"
      @close-modal="closeWelcomeModal"
      width="max-w-[384px]"
      :close="false"
      underline="border-0"
      customPadding="px-5 py-6"
    >
      <div class="">
        <div class="text-gray-800 font-bold text-3xl mb-4">
          Welcome!
        </div>
        <div class="text-gray-600">
          <p class="mb-4">To start working with our platform you will need to create your first project.</p>
          <p class="mb-6">Don’t worry - you can edit the name later</p>
        </div>
        <div>
          <label class="block text-sm font-medium mb-1 text-gray-600" for="email">Project name <span class="text-rose-500">*</span></label>
          <div class="relative">
            <input
              v-model="create_project.name"
              id="name"
              class="form-input w-full"
              type="text"
              placeholder="Project name"
            />
            <div class="absolute transform-translate-y top-1/2 right-2 transition-all">
              <PlusIcon v-if="create_project.name != ''" @click="create_project.name = ''" class="h-5 w-5 text-gray-400 rotate-45 hover:text-gray-600"/>
            </div>
          </div>
          <!-- <span class="text-gray-600 text-xs mt-2.5">You can use up to 11 characters</span> -->
        </div>
      </div>
      <div class="mt-5">
        <div class="flex flex-wrap justify-end space-x-2">
          <!-- <button class="btn-sm border-gray-200 hover:border-gray-300 text-gray-600" @click.stop="modal.basicModalOpen = false">Cancel</button> -->
          <button :class="create_project.name == '' ? 'bg-gray-100 border-gray-200 text-gray-400 pointer-events-none' : 'bg-indigo-500 hover:bg-indigo-600 text-white'" class="btn w-[69px]" @click.stop="saveFirstProject()">Create</button>
        </div>
      </div>
    </ModalBasic>
    <ModalBlank id="info-modal" :modalOpen="reissueModal" @close-modal="closeReissueModal">
      <div class="p-5 flex space-x-4">
         <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100">
            <svg class="w-4 h-4 shrink-0 fill-current text-indigo-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
            </svg>
          </div>
        <div class="grow">
          <div class="mb-2">
            <div class="text-lg font-semibold text-gray-800">Do you really want to reissue the API Key?</div>
          </div>
          <div class="text-sm mb-5">
            <div class="space-y-2">
              <p>Changing the address may result in the deletion of previous transactions</p>
            </div>
          </div>
          <div class="flex flex-wrap justify-end space-x-2">
            <button class="btn-sm border-gray-200 hover:border-gray-300 text-gray-600" @click.stop="closeReissueModal">Cancel</button>
            <button class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" @click="ReissueToken">Create New</button>
          </div>
        </div>
      </div>
    </ModalBlank>
    <ModalBlank id="register-info-modal" :maxWidth="'420px'" :modalOpen="registerInfoModal" @close-modal="registerInfoModal = false">
      <div class="p-5">
        <div class="flex justify-end">
          <svg class="w-4 h-4 fill-gray-400 cursor-pointer" @click.stop="registerInfoModal = false">
            <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
          </svg>
        </div>
        <div class="flex justify-center mb-4">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#818CF8"/>
            <path opacity="0.88" d="M34.3271 18.1941H10.1202C9.62529 18.1941 9.22363 18.5515 9.22363 18.9919V33.3963C9.22363 33.8367 9.62529 34.1941 10.1202 34.1941H34.3271C34.822 34.1941 35.2236 33.8367 35.2236 33.3963V18.9919C35.2236 18.5515 34.822 18.1941 34.3271 18.1941Z" fill="#4F46E5"/>
            <path d="M35.6492 13.1991L11.6779 16.5681C11.1878 16.637 10.8398 17.0468 10.9011 17.4829L12.9058 31.7471C12.9671 32.1832 13.4146 32.4813 13.9046 32.4124L37.876 29.0434C38.366 28.9746 38.714 28.5647 38.6527 28.1286L36.648 13.8644C36.5867 13.4283 36.1393 13.1303 35.6492 13.1991Z" fill="url(#paint0_linear_2902_12420)"/>
            <path opacity="0.72" d="M25.194 25.7765C23.5532 26.0071 22.0363 24.8641 21.8057 23.2232C21.5751 21.5824 22.7181 20.0655 24.359 19.8349C25.9999 19.6043 27.5167 20.7473 27.7473 22.3882C27.9779 24.0291 26.8349 25.5459 25.194 25.7765Z" fill="#6366F1"/>
            <defs>
              <linearGradient id="paint0_linear_2902_12420" x1="10.79" y1="16.6929" x2="13.0168" y2="32.5372" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#A5B4FC"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="text-center text-gray-800 text-lg font-semibold mb-2">Thank you for registering in Kod.Mobi</div>
        <div class="text-center text-gray-600 text-sm mb-[18px]">We give you a gift of <span class="text-gray-800 font-medium">100 credits</span> to the account so that you can get acquainted with Kod.Mobi</div>
        <div class="flex justify-center">
          <button class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white w-[150px]" @click.stop="registerInfoModal = false">Get Started</button>
        </div>
      </div>
    </ModalBlank>
  </div>
</template>

<script>
import SettingSidebar from "@/views/settings/sidebar.vue"
import { mapFields } from 'vuex-map-fields';
import ModalBlank from '@/components/Modals/ModalBlank.vue'
import ModalBasic from '@/components/Modals/ModalBasic.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { PlusIcon } from '@heroicons/vue/outline'
import { mapActions } from 'vuex';
import { XIcon, EyeOffIcon, EyeIcon } from '@heroicons/vue/outline'

export default ({
  data: () => ({
    tooltipOpen: false,
    create_project: {
      id: null,
      name: '',
    },
    registerInfoModal: false,
    loadingSave: false,
  }),
  components: {
    SettingSidebar,
    ModalBasic,
    ModalBlank,
    Tooltip,
    PlusIcon,
    XIcon,
    EyeOffIcon,
    EyeIcon,
  },
  computed: {
    ...mapFields('ProfileModule', {
      profile_id: 'id',
      code_length: 'code_length',
      challenge: 'challenge',
      turnstile_secret: 'turnstile_secret'
    }),
    ...mapFields('AuthModule', {
      successfully: 'register.successfully'
    }),
    ...mapFields('ProjectsModule', {
      all_projects: 'all_projects.projects',
      reissueModal: 'modals.reissueModal.show',
    }),
  },
  methods: {
    ...mapActions('ProjectsModule', ['UPDATE_API_TOKEN', 'UPDATE_SECRET_TOKEN', 'UPDATE_NAME_PROJECT', 'DELETE_PROJECT', 'ADD_PROJECT']),
    ...mapActions('ProfileModule', ['UPDATE_PROFILE']),
    saveFirstProject() {
      this.$store.commit("AuthModule/commitChangeSuccessfully", false)
      this.ADD_PROJECT(this.create_project.name)
      this.registerInfoModal = true;
    },
    closeWelcomeModal() {
      this.$store.commit("AuthModule/commitChangeSuccessfully", false)
    },
    showReissueModal() {
      this.$store.commit('ProjectsModule/commitToggleReissueModal', true)
    },
    closeReissueModal() {
      this.$store.commit('ProjectsModule/commitToggleReissueModal', false)
    },
    tooltipOpenShow(boolean) {
      if(this.all_projects.length === 1)
        this.tooltipOpen = boolean
    },
  },
})
</script>

<style scoped lang="scss">
.border-color {
  color: rgb(233 236 239 / 1);
}
.form-switch input[type=checkbox]:checked + label {
  background-color: #44C157 !important;
}
</style>
